<template>
  <b-table-simple id="ex" bordered responsive class="rounded mb-0" outlined fixed>
    <b-thead>
      <b-tr>
        <b-th v-for="i in header" :key="i">{{i}}</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr v-for="i in 12" :key="i">
        <b-td v-for="j, index in header" :key="100 * i + index"/>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BButton,
  BSpinner,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
export default {
  props: ['header'],
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BButton,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
};
</script>
<style>
</style>