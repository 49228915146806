<template>
    <b-overlay :show="showLoading" spinner-variant="primary" blur="0" opacity=".35" rounded="sm">
        <div class="mx-0" id="report">
            <div style="width: 100%">
                <h4 class="mb-2">Select Report</h4>
                <div class="row mb-2">
                    <div class="col-2">
                        <p>Report Name</p>
                        <v-select v-model="report_name" :options="report_name_op" :clearable="false"> </v-select>
                    </div>

                    <div class="col-2">
                        <p>Start Date</p>
                        <flat-pickr
                            v-model="start_date"
                            :config="{ maxDate: today }"
                            class="form-control shadow-none my-shadow custom-input"
                            disabled
                            style="color: #888"
                        />
                    </div>

                    <div class="col-2">
                        <p>End Date</p>
                        <flat-pickr
                            v-model="end_date"
                            :config="{ minDate: start_date, maxDate: today }"
                            class="form-control my-shadow custom-input"
                            :clearable="false"
                        />
                    </div>

                    <div class="col-2" id="tree-groups-select">
                        <p>Groups</p>
                        <treeselect
                            v-model="groups"
                            :multiple="true"
                            :options="platform_groups"
                            placeholder="Select groups"
                            :maxHeight="600"
                            class="custom-input"
                        />
                    </div>

                    <div class="col-2" id="items">
                        <p>Items</p>
                        <b-dropdown
                            class="custom-input w-100 card-footer-color dropdown-icon-wrapper my-shadow"
                            variant="flat"
                            toggle-class="py-50 px-75"
                            menu-class="w-100"
                        >
                            <template #button-content>
                                <div class="w-100 d-flex justify-content-between text-white align-items-center">
                                    <div>{{ item_label }}</div>
                                    <font-awesome-icon
                                        :icon="['fas', 'caret-down']"
                                        v-if="!isItemsDropdownOpened"
                                        @click="
                                            () => {
                                                isItemsDropdownOpened = true;
                                            }
                                        "
                                        class="font-small-4"
                                    />
                                    <font-awesome-icon
                                        :icon="['fas', 'caret-up']"
                                        v-else
                                        @click="
                                            () => {
                                                isItemsDropdownOpened = false;
                                            }
                                        "
                                        class="font-small-4"
                                    />
                                </div>
                            </template>
                            <b-dropdown-form>
                                <div class="d-flex pb-1 align-items-center">
                                    <b-form-checkbox
                                        @change="selectAllItem"
                                        :checked="item_label == 'All Accounts'"
                                        style="transform: scale(0.75)"
                                    >
                                    </b-form-checkbox>
                                    <span class="font-small-3">
                                        Select All
                                    </span>
                                </div>

                                <div
                                    style="margin-bottom: 6px"
                                    class="cursor-pointer"
                                    @click="account_items_collapse = !account_items_collapse"
                                >
                                    Accounts
                                    <feather-icon
                                        :icon="account_items_collapse ? 'ChevronRightIcon' : 'ChevronDownIcon'"
                                        size="14"
                                        class="align-middle"
                                    />
                                </div>

                                <div v-if="!account_items_collapse">
                                    <div
                                        v-for="login in all_account_login"
                                        :key="login"
                                        class="d-flex"
                                        style="padding: 3px 0 3px 15px"
                                    >
                                        <b-form-checkbox
                                            v-model="item_selected"
                                            :value="login"
                                            style="transform: scale(0.75)"
                                        />
                                        <span class="font-small-3">{{ login }}</span>
                                    </div>
                                </div>
                            </b-dropdown-form>
                        </b-dropdown>
                    </div>
                </div>

                <div class="row mb-2 align-items-end">
                    <div class="col-2">
                        <p>Format:</p>
                        <v-select
                            :clearable="false"
                            v-model="format"
                            :options="[
                                { value: '.csv', text: 'CSV (Text File)' },
                                { value: '.xlsx', text: 'XLSX (Excel File)' },
                                { value: '.html', text: 'HTML (Browser File)' },
                            ]"
                            label="text"
                            :reduce="(i) => i.value"
                        />
                    </div>

                    <div
                        class="col-1 font-weight-bold cursor-pointer"
                        @click="
                            () => {
                                showPreview = true;
                                header = [...previewHeader[report_name]];
                            }
                        "
                    >
                        <div class="mb-50 ml-2">
                            <feather-icon icon="EyeIcon" class="mr-50" />
                            <span class="align-middle">Preview</span>
                        </div>
                    </div>

                    <div class="col-1 pl-50">
                        <b-button
                            variant="success"
                            size="sm"
                            class="mt-2 py-50 d-flex"
                            @click="download()"
                            :disabled="groups.length == 0 && item_selected.length == 0"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="DownloadIcon" class="mr-50" />
                                <span class="font-small-3">Download</span>
                            </div>
                        </b-button>
                    </div>
                </div>
                <!-- Preview Area -->
                <h4 class="mb-2">Preview</h4>
                <div class="my-shadow rounded" style="width: 100%; height: 50vh; overflow: hidden" id="ex">
                    <b-card class="rounded w-100 h-100">
                        <Table v-if="showPreview" :header="header" />
                    </b-card>
                </div>
            </div>
        </div>
    </b-overlay>
</template>
<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BCard, BButton, BOverlay, BDropdown, BDropdownForm, BFormCheckbox } from "bootstrap-vue";
import axios from "@axios";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "./vue-treeselect.css";
import Table from "./Table";

export default {
    components: {
        vSelect,
        BCard,
        flatPickr,
        BButton,
        BOverlay,
        BDropdown,
        BDropdownForm,
        BFormCheckbox,
        Treeselect,
        Table,
    },

    data() {
        return {
            showLoading: false,
            report_name: "Daily Report",
            format: ".csv",
            today: moment().format("YYYY-MM-DD"),
            start_date: "",
            end_date: moment().format("YYYY-MM-DD"),
            groups: [],
            item_selected: [],
            isItemsDropdownOpened: false,
            report_name_op: ["Daily Report", "WD and Dep Report", "Profit/Loss Report"],
            header: [],
            platform_groups: [],
            all_account_login: [],
            account_items_collapse: false,
            showPreview: false,
        };
    },

    computed: {
        companyCurrency() {
            const riskMangementSettings = this.$store.getters["companySettings/settingsByPath"](["risk_management"]);
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
        previewHeader() {
            return {
                "Daily Report": [
                    "Login",
                    "Group",
                    "Current Credit",
                    "Current Equity",
                    "Net Deposit",
                    "Actual Profit",
                    "Percentage Change in Equity",
                    "This Month Actual Profit",
                    "Last Month's Actual Profit",
                    "Overall Actual Profit",
                    "Current Months Lots",
                    " Withdrawable",
                ],
                "WD and Dep Report": [
                    "Login",
                    "Group",
                    "Currency",
                    "Comment",
                    "Agent",
                    "Equity",
                    "Credit",
                    " Overall Net Deposit (CL)",
                    " Overall Net Bonuses (BN)",
                    " Monthly Net Deposit (CL)",
                    " Monthly Net Bonuses (BN)",
                    " Daily Net Deposit (CL)",
                    " Daily Net Bonuses (BN)",
                    "Daily Volume (lots)",
                    "Monthly Volume (lots)",
                ],
                "Profit/Loss Report": [
                    "Login",
                    "Group",
                    "Currency",
                    "Comment",
                    "Agent",
                    "Equity",
                    "Credit",
                    "Current Wdable Amount",
                    "Overall Lots",
                    "Daily PL",
                    "Monthly PL",
                    "Overall PL",
                    "Daily Volume (lots)",
                    "Monthly Volume (lots)",
                    `Daily PL(${this.companyCurrency})`,
                    `Monthly PL(${this.companyCurrency})`,
                    `Current Wdable Amount (${this.companyCurrency})`,
                ],
            };
        },
        item_label() {
            if (this.item_selected.length == 0) return "Select Item";
            if (JSON.stringify([...this.item_selected].sort()) == JSON.stringify([...this.all_account_login].sort()))
                return "All Accounts";
            return [...this.item_selected].join(", ").slice(0, 20) + "...";
        },

        group_label() {
            if (this.groups.length == 0) return "Select Group";
            if (JSON.stringify([...this.groups].sort()) == JSON.stringify([...this.platform_groups].sort()))
                return "All Accounts";
            return this.groups.join(", ").slice(0, 20) + "...";
        },

        is_end_date_today() {
            return this.end_date == this.today;
        },
    },

    methods: {
        async download() {
            // console.log(this.is_end_date_today)
            this.showLoading = true;
            let properties,
                values,
                header,
                need_convert = [];
            let update_action_name = "UpdateCustomTimeframeValues";
            if (this.report_name == "Daily Report") {
                properties = ["login", "server", "group"];
                values = [
                    "CurrentCredit",
                    "CurrentEquity",
                    "NetDeposits",
                    "ActualPL",
                    "%ChangeEquity",
                    "PLThisMonth",
                    "PLLastMonth",
                    "PLOverall",
                    "#LotsThisMonth",
                    "Withdrawable",
                ];
                header = [
                    "Login",
                    "Server",
                    "Group",
                    "Current Credit",
                    "Current Equity",
                    "Net Deposit",
                    "Actual Profit",
                    "Percentage Change in Equity",
                    "This Month Actual Profit",
                    "Last Month's Actual Profit",
                    "Overall Actual Profit",
                    "Current Months Lots",
                    "Withdrawable",
                ];
                update_action_name = "UpdateCustomTimeframeDailyReport";
            } else if (this.report_name == "WD and Dep Report") {
                properties = ["login", "server", "group", "currency", "mt_account.comment", "mt_account.agent_account"];
                values = [
                    "CurrentEquity",
                    "CurrentCredit",
                    "NetDepositsOverall",
                    "OverallNetCredits",
                    "MonthlyNetDeposit",
                    "MonthlyNetCredits",
                    "NetDeposits",
                    "NetCredits",
                    "ClosedLotsToday",
                    "#LotsThisMonth",
                ];
                header = [
                    "Login",
                    "Server",
                    "Group",
                    "Currency",
                    "Comment",
                    "Agent",
                    "Equity",
                    "Credit",
                    "Overall Net Deposit(CL)",
                    "Overall Net Bonuses(BN)",
                    "Monthly Net Deposit(CL)",
                    "Monthly Net Bonuses(BN)",
                    "Daily Net Deposit(CL)",
                    "Daily Net Bonuses(BN)",
                    "Daily Volume(lots)",
                    "Monthly Volume(lots)",
                ];
                update_action_name = "UpdateCustomTimeframeWDReport";
            } else if (this.report_name == "Profit/Loss Report") {
                header = [
                    "Login",
                    "Server",
                    "Group",
                    "Currency",
                    "Comment",
                    "Agent",
                    "Equity",
                    "Credit",
                    "Current Wdable Amount",
                    "Overall Lots",
                    "Daily PL",
                    "Monthly PL",
                    "Overall PL",
                    "Daily Volume(lots)",
                    "Monthly Volume(lots)",
                    `Daily PL(${this.companyCurrency})`,
                    `Monthly PL(${this.companyCurrency})`,
                    `Current Wdable Amount(${this.companyCurrency})`,
                ];
                properties = ["login", "server", "group", "currency", "mt_account.comment", "mt_account.agent_account"];
                if (!this.is_end_date_today) {
                    // UserReport
                    values = [
                        "EquityAccCurrency",
                        "CreditAccCurrency",
                        "WdableAccCurrency",
                        "OverallLots",
                        "ActualPLAccCurrency",
                        "PLThisMonthAccCurrency",
                        "PLOverallAccCurrency",
                        "ClosedLotsToday",
                        "#LotsThisMonth",
                        "ActualPL",
                        "PLThisMonth",
                        "Withdrawable",
                    ];
                    update_action_name = "UpdateCustomTimeframePLReport";
                } else {
                    // CurrentUserStatistics
                    values = [
                        "CurrentEquityAccCurrency",
                        "CurrentCreditAccCurrency",
                        "WdableAccCurrency",
                        "OverallLots",
                        "ActualPLAccCurrency",
                        "PLThisMonthAccCurrency",
                        "PLOverallAccCurrency",
                        "ClosedLotsToday",
                        "#LotsThisMonth",
                        "ActualPL",
                        "PLThisMonth",
                        "Withdrawable",
                    ];
                }
            } else return;

            var filter = { properties: { path: this.groups, login: this.item_selected } };
            var from_date = this.start_date
                ? moment
                      .utc(this.start_date)
                      .startOf("day")
                      .unix()
                : moment
                      .utc("1970-01-01")
                      .startOf("day")
                      .unix();
            var to_date = moment
                .utc(this.end_date)
                .endOf("day")
                .unix();

            var metric_definition_name = "UserReport";
            if (this.is_end_date_today) metric_definition_name = "CurrentUserStatistics";
            else {
                await axios.post(`${process.env.VUE_APP_API_URL}metric/UserReport/update_custom_timeframe`, {
                    update_action_name,
                    from_date: from_date,
                    to_date: to_date,
                });
            }

            if (this.format == ".csv") {
                await axios
                    .get(`${process.env.VUE_APP_API_URL}metric/${metric_definition_name}/export_csv`, {
                        params: {
                            header: JSON.stringify(header),
                            filter: JSON.stringify(filter),
                            properties: JSON.stringify(properties),
                            values: JSON.stringify(values),
                            need_convert: JSON.stringify(need_convert),
                        },
                    })
                    .then((res) => {
                        const anchor = document.createElement("a");
                        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
                        anchor.target = "_blank";
                        anchor.download = `${this.report_name}_${this.end_date.replaceAll("-", "")}.csv`;
                        anchor.click();
                    });
            }
            if (this.format == ".xlsx") {
                await axios({
                    method: "get",
                    url: `${process.env.VUE_APP_API_URL}metric/${metric_definition_name}/export_xlsx`,
                    params: {
                        header: JSON.stringify(header),
                        filter: JSON.stringify(filter),
                        properties: JSON.stringify(properties),
                        values: JSON.stringify(values),
                        need_convert: JSON.stringify(need_convert),
                    },
                    responseType: "blob",
                }).then((res) => {
                    // console.log(res.data)
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", `${this.report_name}_${this.end_date.replaceAll("-", "")}.xls`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
            }
            if (this.format == ".html") {
                alert("Not avaiable at the moment!");
            }
            this.showLoading = false;
        },

        selectAllItem(e) {
            this.item_selected = e ? [...this.all_account_login] : [];
        },

        format_tree_data(data) {
            let result = [];
            Object.keys(data).forEach((name) => {
                var path = name;
                if (data[name].children)
                    result.push({
                        id: name,
                        isDefaultExpanded: true,
                        label: data[name].settings.name,
                        children: this.format_tree_data(data[name].children),
                    });
                else result.push({ id: name, label: data[name].settings.name, isDefaultExpanded: true });
            });
            return result;
        },

        fetch_groups() {
            axios
                // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
                //? mockData
                .get("/GroupOrganization/data")
                .then((res) => {
                    this.platform_groups = this.format_tree_data(res.data.children);
                    this.groups = Object.keys(res.data.children);
                });
        },

        fetch_accounts() {
            axios
                // .get(`${process.env.VUE_APP_API_URL}mt_accounts`)
                //? mockData
                .get("/MtAccount/data")
                .then((res) => {
                    this.item_selected = res.data.map((a) => a.login);
                    this.all_account_login = this.item_selected.sort((a, b) => a - b);
                });
        },
    },

    mounted() {
        this.fetch_groups();

        this.fetch_accounts();
    },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/variables/_variables-components.scss";
#report {
    ::v-deep .vs__dropdown-toggle,
    .custom-input {
        border-radius: 5px !important;
        height: 34px !important;
    }
    ::v-deep .vue-treeselect__control {
        border-radius: 5px !important;
        box-shadow: $theme-dark-box-shadow;
        max-height: 34px;
        border-color: #3d3d3d;
        background-color: #18191a !important;
    }

    ::v-deep .vue-treeselect__menu {
        background-color: #18191a !important;
        min-width: 216px;
        border: 1px solid #333;
        border-radius: 5px;
        max-height: 500px !important;
    }

    ::v-deep .vue-treeselect__multi-value-item {
        border-radius: 5px;
    }
    #ex {
        ::v-deep .card-body {
            padding: 0;
        }

        ::v-deep td {
            border-top: 1px solid;
            height: 40px;
        }

        ::v-deep th {
            padding-left: 4px;
            padding-right: 4px;
            height: 85px;
        }
    }
}

/* #tree-groups-select .vue-treeselect__multi-value {
  height: 34px;
  overflow: hidden
} */

#items {
    ::v-deep .dropdown-menu {
        min-width: 216px !important;
        font-size: 12.6px;
        color: white;
        max-height: 500px;
        overflow: auto;
        border: 0px solid #404656 !important;
    }
    ::v-deep .b-dropdown {
        background: #18191a;
        border: 1px solid #3d3d3d !important;
    }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
